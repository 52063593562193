.hero {
    padding-top: 80px;
}

.lastname {
    color: skyblue;
}

.subtitle {
    font-size: calc(10px + 2vmin);
}

.mob-img {
    width: 70%;
}

@media screen and (max-width: 768px) {
    .mob-img {
        margin-top: 80px;
        width: auto;
        max-width: 300px;
        margin-bottom: 50px;
    }
}